import { AppComponent } from './app.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { InstallationComponent } from './installation/installation.component';


export const routes = [

  { path: '', component: AppComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'installation', component: InstallationComponent },
  { path: 'menu', loadChildren: './menu/menu.module#MenuModule' }
];

