import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { routes } from './routes';


/**MODULOS */
import {  MenuModule  } from './menu/menu.module';

/**SERVICIOS */
import { Globals } from './globals';
import {  InstalacionService  } from './services/instalacion.service';
import {  NotificacionesService  } from './services/notificaciones.service';



/**templates */
import { WelcomeComponent } from './welcome/welcome.component';
import { InstallationComponent } from './installation/installation.component';


@NgModule({
  declarations: [
    AppComponent,
    WelcomeComponent,
    InstallationComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MenuModule


  ],
  providers: [
    Globals,
    InstalacionService,
    NotificacionesService
  ],
  bootstrap: [AppComponent],
  exports: [RouterModule]
})
export class AppModule { }
