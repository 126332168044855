import { Component, OnInit, HostListener } from '@angular/core';
import { EventosService } from './../../services/eventos.service';
import { Globals }  from './../../globals';
import { Evento }  from'./../../evento';
import { Router } from '@angular/router';
import { PaginationInstance } from 'ngx-pagination';
import {  NgxPaginationModule } from 'ngx-pagination';

/**iconos */
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.scss']
})


export class EventosComponent implements OnInit {

  texto:string = '';

  shows: Evento[] = [];

  famapmarkeralt = faMapMarkerAlt;
  chevron = faChevronRight;

  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 1,
    currentPage: 1
  };


  @HostListener('window:keyup', ['$event'])

  keyEvent(event: KeyboardEvent) {

    if (event.keyCode === 13) {
      this.router.navigate(['menu/rooms']);
    }

    if (event.keyCode === 39) {
      let pageStay = this.config.currentPage + 1;
      let totalItems: number = this.shows.length;
      if(pageStay <= totalItems ){
        this.config.currentPage = pageStay;
      }else{
        pageStay = 1;
        this.config.currentPage = pageStay;
      }

    }
  }
  constructor(private globals: Globals, private miservicio: EventosService, private router: Router) { }

  base = this.globals.imagenUrl;

  ngOnInit() {
    this.show();
  }

  show(){
    this.miservicio.Eventos().subscribe(res=>{
      this.shows = res['data'];
    },
    e=>{
      if(e.code === 0){
        this.texto = 'No hay conexion de con el servidor, HOST no disponible. Revisar conexion de red.'
      }
      if(e.code === 500){
        this.texto = 'Servidor no puede accesar a base de datos'
      }
      this.router.navigate(['menu/error', this.texto]);

    }

    )

  }

}
