import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
/**iconos */
import { faConciergeBell } from '@fortawesome/free-solid-svg-icons';

/**interface */
import { Recepcion } from './../../recepcion';

/**services */
import { RecepcionService } from './../../services/recepcion.service';



@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {

  answer:any = [];

  recepcion: Recepcion[] = [];

  faConciergeBell = faConciergeBell;
  constructor(private router: Router, private miservicio: RecepcionService) { }

  ngOnInit() {
    this.sendNotificacion();
  }

  sendNotificacion(){
    let notArray:Recepcion = {
      habitacion: Number(localStorage.getItem('HAB')),
      titulo: "Desde la habitación",
      mensaje: "Notificando de Checkout"
    }

    this.miservicio.CrearNotificaion(notArray).subscribe((data: {}) => {
      this.answer = data;
      console.log( this.answer);
      console.log(notArray);
    }
    )
    
  }

}
