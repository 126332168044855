import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';

/**ROUTER 2 */
import {  routesM } from './routeMenu';

/**SERVICIOS */
import { Globals } from './../globals';
import { RoomService } from './../services/room.service';
import { EventosService } from './../services/eventos.service';
import { RecepcionService } from './../services/recepcion.service';
import { TurismoService } from './../services/turismo.service';
import { ServiciosService } from './../services/servicios.service';
import {  NotificacionesService  } from './../services/notificaciones.service';


/**templates */
import { MenuComponent } from './menu.component';
import { RoomComponent } from './room/room.component';
import { ErrorComponent } from './error/error.component';
import { DespertadorComponent } from './despertador/despertador.component';
import { MensajeComponent } from './mensaje/mensaje.component';
import { EventosComponent } from './eventos/eventos.component';
import { RecepcionComponent } from './recepcion/recepcion.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { TurismoComponent } from './turismo/turismo.component';
import { ServicosComponent } from './servicos/servicos.component';
import { RecepconfirmComponent } from './recepconfirm/recepconfirm.component';



@NgModule({
  declarations: [
    MenuComponent,
    RoomComponent,
    ErrorComponent,
    DespertadorComponent,
    MensajeComponent,
    EventosComponent,
    RecepcionComponent,
    CheckoutComponent,
    TurismoComponent,
    ServicosComponent,
    RecepconfirmComponent,

  ],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routesM),
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularSvgIconModule,
    NgxPaginationModule,
    ToastrModule.forRoot(
      {
        timeOut: 10000,
        positionClass: 'toast-top-right',
        preventDuplicates: true,
      }
    )
  ],
  bootstrap: [
    MenuComponent
  ],
  providers: [
    Globals,
    RoomService,
    EventosService,
    RecepcionService,
    TurismoService,
    ServiciosService,
    NotificacionesService

  ],
  exports:[
    RouterModule,
    MenuComponent
  ]
})
export class MenuModule { }







