import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Globals }  from './../globals';
import { Habitacion } from './../habitacion'
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InstalacionService {

  constructor(private http: HttpClient, private globals: Globals ) { }
  hab: Habitacion[];
  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'text/plain'
    })
  }

  Instalacion2(NumeroHabitacion,pin): Promise<any>{
    this.hab = [{
      "NumeroHabitacion" : NumeroHabitacion,
      "pin" : pin,
    }];
    let url = this.globals.baseUrl + '/televisores/created?pin=' + pin + '&NumeroHabitacion=' + NumeroHabitacion;
    return this.http.post( url , this.hab, this.httpOptions )
    .toPromise()
    .then(
      result=>{
        return result = result
      }).catch(err=>{
        return Promise.reject(err.error  || 'Server error')
      });



  }

}
