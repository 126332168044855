import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  @HostListener('window:keyup', ['$event'])

  keyEvent(event: KeyboardEvent) {

    if (event.keyCode === 403) {
      this.router.navigate(['menu/eventos']);
    }

    if (event.keyCode === 404) {
      this.router.navigate(['menu/turismo'])
    }

    if (event.keyCode === 405) {
      this.router.navigate(['menu/servicios'])
    }

    if (event.keyCode === 406) {
      this.router.navigate(['menu/recepcion'])
    }
  }

  title = 'hospitalityHab';
  logoMenu = 'assets/images/tv/logo-principal.png';

  constructor( private router: Router) { }

  ngOnInit() {

  }


}
