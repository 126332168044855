import { MensajeComponent } from './mensaje/mensaje.component';
import { MenuComponent } from './menu.component';
import { RoomComponent } from './room/room.component';
import { ErrorComponent } from './error/error.component';
import { DespertadorComponent } from './despertador/despertador.component';
import { RecepcionComponent } from './recepcion/recepcion.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { EventosComponent } from './eventos/eventos.component';
import { TurismoComponent } from './turismo/turismo.component';
import { ServicosComponent } from './servicos/servicos.component';
import { RecepconfirmComponent } from './recepconfirm/recepconfirm.component';

export const routesM = [

  { path: 'menu', component: MenuComponent, children:
    [
      { path: 'room', component: RoomComponent, pathMatch: 'full' },
      { path: 'error/:texto', component: ErrorComponent, pathMatch: 'full' },
      { path: 'despertador', component: DespertadorComponent, pathMatch: 'full' },
      { path: 'mensajes', component: MensajeComponent, pathMatch: 'full' },
      { path: 'recepcion', component: RecepcionComponent, pathMatch: 'full' },
      { path: 'confirm', component: RecepconfirmComponent, pathMatch: 'full' },
      { path: 'checkout', component: CheckoutComponent, pathMatch: 'full' },
      { path: 'eventos', component: EventosComponent, pathMatch: 'full' },
      { path: 'turismo', component: TurismoComponent, pathMatch: 'full' },
      { path: 'servicios', component: ServicosComponent, pathMatch: 'full' },

    ]
  }

];
