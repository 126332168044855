import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';

/**iconos */
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
/**interface */
import { Recepcion } from './../../recepcion';

/**services */
import { RecepcionService } from './../../services/recepcion.service';



@Component({
  selector: 'app-recepcion',
  templateUrl: './recepcion.component.html',
  styleUrls: ['./recepcion.component.scss']
})
export class RecepcionComponent implements OnInit {

  @HostListener('window:keyup', ['$event'])

  keyEvent(event: KeyboardEvent) {

   /* if (event.keyCode === 13) {
      this.router.navigate(['menu/confirm']);
    }*/
  }
  answer:any = [];

  recepcion: Recepcion[] = [];
  faPhoneAlt = faPhoneAlt;

  constructor(private router: Router, private miservicio: RecepcionService) { }

  ngOnInit() {


  }

  confirmNotificacion(){
    this.router.navigate(['menu/confirm']);
  }


}
