import { Component, OnInit, HostListener } from '@angular/core';
import { ServiciosService } from './../../services/servicios.service';
import { Globals }  from './../../globals';
import { Servicio }  from'./../../servicio';
import { Router } from '@angular/router';
import {PaginationInstance} from 'ngx-pagination';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-servicos',
  templateUrl: './servicos.component.html',
  styleUrls: ['./servicos.component.scss']
})
export class ServicosComponent implements OnInit {

  texto:string = '';
  chevron = faChevronRight;
  base = this.globals.imagenUrl;

  servicess: Servicio[] = [];
  @HostListener('window:keyup', ['$event'])

  keyEvent(event: KeyboardEvent) {

    if (event.keyCode === 13) {
      this.router.navigate(['menu/rooms']);
    }

    if (event.keyCode === 39) {
      let pageStay = this.config.currentPage + 1;
      let totalItems: number = this.servicess.length;
      if(pageStay <= totalItems ){
        this.config.currentPage = pageStay;
      }else{
        pageStay = 1;
        this.config.currentPage = pageStay;
      }

    }
  }
  constructor(private globals: Globals, private miservicio: ServiciosService, private router: Router) { }

  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 1,
    currentPage: 1
  };

  ngOnInit() {
    this.serv();
  }

  serv(){
    this.miservicio.Servicios().subscribe(res=>{
      this.servicess = res['data'];
    },
    e=>{
      if(e.code === 0){
        this.texto = 'No hay conexion de con el servidor, HOST no disponible. Revisar conexion de red.'
      }
      if(e.code === 500){
        this.texto = 'Servidor no puede accesar a base de datos'
      }
      this.router.navigate(['menu/error', this.texto]);

    }

    )

  }

}
