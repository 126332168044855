import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  image = '/assets/images/home/fondo-home.jpg';
  habitacion:any;

  constructor(private router: Router) { }

  ngOnInit() {
    this.checkHab();
  }

  checkHab(){

    this.habitacion = localStorage.getItem('HAB');
    if(this.habitacion === null){
      console.log("se debe crear hab");
      setTimeout(()=> {
        this.router.navigate(['installation']); },5000);

    
      
    }else{
      console.log(this.habitacion);
      setTimeout(()=> {
        this.router.navigate(['menu/room']); },5000);

    }
  }


}
