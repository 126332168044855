import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { Evento }  from './../evento';
import { Globals } from './../globals';
import { retry, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EventosService {

  constructor(private globals: Globals, private miservicio: HttpClient, private router: Router) { }


  Eventos() : Observable<Evento[]> {
    return this.miservicio
      .get<Evento[]>(this.globals.baseUrl + '/eventos').pipe(
        retry(1),
        catchError(this.errorHandl)
      );

  }

  // Error handling
  errorHandl(error) {
    let errorMessage = '';
    let code:number;
    let mensaje: string;
    let sendMessage:any;
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      code = error.status;
      mensaje = error.message;
      sendMessage = {'code': code, 'mensaje': mensaje};
      errorMessage = `Code: ${error.status},Message: ${error.message}`;

    }
    return throwError(sendMessage);


 }



}
