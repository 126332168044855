import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'hospitalityHab';

  texto:string = '';



  constructor( private router: Router) { }

  ngOnInit(): void {
    this.router.navigate(['welcome']);
  }






}
