import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';


/**iconos */
import { faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
/**interface */
import { Recepcion } from './../../recepcion';

/**services */
import { RecepcionService } from './../../services/recepcion.service';


@Component({
  selector: 'app-recepconfirm',
  templateUrl: './recepconfirm.component.html',
  styleUrls: ['./recepconfirm.component.scss']
})
export class RecepconfirmComponent implements OnInit {

  @HostListener('window:keyup', ['$event'])

  keyEvent(event: KeyboardEvent) {

   if (event.keyCode === 13) {
    this.sendNotificacion();
    // this.router.navigate(['menu/room']);
   }
 }
  answer:any = [];

  recepcion: Recepcion[] = [];
  faPhoneVolume = faPhoneVolume;

  constructor(private router: Router, private miservicio: RecepcionService ) { }

  ngOnInit() {
    this.sendNotificacion();
  }

  sendNotificacion(){
    let notArray:Recepcion = {
      habitacion: Number(localStorage.getItem('HAB')),
      titulo: "Desde la habitación",
      mensaje: "Ponerse en contacto"
    }

    this.miservicio.CrearNotificaion(notArray).subscribe((data: {}) => {
      this.answer = data;
      /*console.log( this.answer);
      console.log(notArray);*/
    }


    )

  }

}
