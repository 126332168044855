import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Globals }  from './../globals';
import { Recepcion } from './../recepcion'
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class RecepcionService {

  constructor(private http: HttpClient, private globals: Globals) { }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'text/plain'
    })
  }

  CrearNotificaion(notArray:any): Observable<Recepcion>{
    var url = this.globals.baseUrl + '/notificacions/save?habitacion=' + notArray['habitacion'] + '&titulo=' + notArray['titulo'] + '&mensaje=' + notArray['mensaje'];
    return this.http.post<Recepcion>(url, notArray, this.httpOptions)
    .pipe(
      retry(1),
      catchError(this.errorHandl)
    )
  }

 // Error handling
  errorHandl(error) {
    let errorMessage = '';
    if(error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(errorMessage);
    return throwError(errorMessage);
 }



}
