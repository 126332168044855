import { Component, OnInit, HostListener } from '@angular/core';
import { RoomService } from './../../services/room.service';
import { Globals }  from './../../globals';
import { Room }  from'./../../room';
import { Router } from '@angular/router';
import {  NotificacionesService  } from './../../services/notificaciones.service';
import { Notificaciones }  from './../../notificaciones';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss']
})

export class RoomComponent implements OnInit {

  @HostListener('window:keyup', ['$event'])

  keyEvent(event: KeyboardEvent) {
    //console.log(event.keyCode);

    if (event.keyCode === 13) {
      this.router.navigate(['menu/room']);
      console.log("cerrar notification");
    }




  }



  texto:string = '';

  bnvmsg: Room[] = [];

  tecla: any;

  habitacionNumero:number = Number(localStorage.getItem('HAB'));
  noti: Notificaciones[] = [];

  constructor(private globals: Globals,
    private miservicio: RoomService,
    private router: Router,
    private notificaciones: NotificacionesService,
    private toastr: ToastrService) { }

  ngOnInit() {
    this.mensaje();

    setInterval(()=> { this.mensajes(); },5000);



  }

  mensaje(){
    this.miservicio.MensajeBienvenida(this.habitacionNumero).subscribe(res=>{
      this.bnvmsg = res['resultado'];
      //console.log( this.bnvmsg);
    },
    e=>{
      if(e.code === 0){
        this.texto = 'No hay conexion de con el servidor, HOST no disponible. Revisar conexion de red.'
      }
      if(e.code === 500){
        this.texto = 'Servidor no puede accesar a base de datos'
      }
      this.router.navigate(['menu/error', this.texto]);

    }

    )

  }

  onKeydown(event) {
    console.log(event);
    this.tecla = event.keyCode;

}

mensajes(){
  this.notificaciones.Mensaje(this.habitacionNumero).subscribe(res=>{
    this.noti = res['resultado'];
    if(res['resultado'] === "No hay Mensaje"){
      //console.log("No hay notificación");
    }else{
      this.showSuccess(this.noti);
    }

  },
  e=>{
    if(e.code === 0){
      this.texto = 'No hay conexion de con el servidor, HOST no disponible. Revisar conexion de red.'
    }
    if(e.code === 500){
      this.texto = 'Servidor no puede accesar a base de datos'
    }
    this.router.navigate(['menu/error', this.texto]);

  }



  )


}

showSuccess(noti2:any) {
  //console.log();
  for (let i = 0; i < noti2.length; i++) {
    this.toastr.warning(this.noti[i]['comentario'], '', {
      closeButton: true,
      disableTimeOut: true,
    });
    this.eliminarNotificacion(this.noti[i]['id']);

  }

}

eliminarNotificacion(pos:any){
  this.notificaciones.Eliminarmensaje(pos).subscribe(res=>{
    //console.log(res);
  },
  e=>{
    if(e.code === 0){
      this.texto = 'No hay conexion de con el servidor, HOST no disponible. Revisar conexion de red.'
    }
    if(e.code === 500){
      this.texto = 'Servidor no puede accesar a base de datos'
    }


  }
  )


}




}
