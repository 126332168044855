import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';


/**iconos */
import { faBed } from '@fortawesome/free-solid-svg-icons';
import { faUnlock } from '@fortawesome/free-solid-svg-icons';

/**component AM */

/**services */
import { InstalacionService } from './../services/instalacion.service';

@Component({
  selector: 'app-installation',
  templateUrl: './installation.component.html',
  styleUrls: ['./installation.component.scss']
})
export class InstallationComponent implements OnInit {

    imageLogo = '/assets/images/instalacion/logo_hospitality-neg-al.png';
    titulo = 'Instalación';
    subtitulo = 'Ingrese número de habitación y el pin';
    pin: any;
    hab: any;
    faBed = faBed;
    faUnlock = faUnlock;

  createFormGroup(){
    return new FormGroup({
      numberPin: new FormControl('', [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
      numberHab: new FormControl(' ', [Validators.required, Validators.minLength(2)])
    });
  }

  habForm: FormGroup;
  texto:string = '';
  constructor(private router: Router, private miservicio: InstalacionService) { }

  ngOnInit() {
    this.habForm = this.createFormGroup();


  }

  answer:any = [];

  debug:any;

  habiF:any;

  get numberPin() { return this.habForm.get('numberPin'); }
  get numberHab() { return this.habForm.get('numberHab'); }

  check(){
    if(this.numberPin.valid){
      this.pin = Number(this.numberPin.value);
      if(this.numberHab.valid){
        //this.habArray['NumeroHabitacion'] = this.numberHab.value.trim();
        //this.habArray['pin'] = this.pin.trim();
        this.hab = this.numberHab.value.trim();
        this.habiF = "Habitación Número:" + this.numberHab.value.trim() +  "Agregada|mensaje:";
        this.miservicio.Instalacion2(this.numberHab.value.trim(),this.pin ).then((data: {}) => {
          this.answer = data;
          console.log(this.answer);

          if(this.answer.code === "203")
          {
            this.debug="PIN ERRONEO";
            this.habForm.reset();
            this.debug = "";
            this.habiF = "";
            return;
          }
          this.debug = "ingreso";

          setTimeout(()=> {
            localStorage.setItem("HAB", this.hab);
            this.router.navigate(['welcome']); },2000);
          }).catch(
          err => {
            this.debug=JSON.stringify(err.error);
            console.log(err);

          }
        );
        this.habForm.reset();
        }else{
          this.habForm.reset();
      }
    }
  }






}
