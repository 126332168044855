import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
/**iconos */
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})

export class ErrorComponent implements OnInit {

  error:string = '';

  faExclamationTriangle = faExclamationTriangle;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.error = this.activatedRoute.snapshot.paramMap.get('texto');

    console.log(this.error);

    setTimeout(()=> {
      this.router.navigate(['menu/room']); },6000);
    }

}
