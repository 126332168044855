import { Component, OnInit, HostListener } from '@angular/core';
import { TurismoService } from './../../services/turismo.service';
import { Globals }  from './../../globals';
import { Turismo }  from'./../../turismo';
import { Router } from '@angular/router';
import {PaginationInstance} from 'ngx-pagination';

import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-turismo',
  templateUrl: './turismo.component.html',
  styleUrls: ['./turismo.component.scss']
})
export class TurismoComponent implements OnInit {


  texto:string = '';

  sitios: Turismo[] = [];

  famapmarkeralt = faMapMarkerAlt;
  chevron = faChevronRight;

  public config: PaginationInstance = {
    id: 'custom',
    itemsPerPage: 1,
    currentPage: 1
  };

  base = this.globals.imagenUrl;

  @HostListener('window:keyup', ['$event'])

  keyEvent(event: KeyboardEvent) {

    if (event.keyCode === 13) {
      this.router.navigate(['menu/rooms']);
    }

    if (event.keyCode === 39) {
      let pageStay = this.config.currentPage + 1;
      let totalItems: number = this.sitios.length;
      if(pageStay <= totalItems ){
        this.config.currentPage = pageStay;
      }else{
        pageStay = 1;
        this.config.currentPage = pageStay;
      }

    }
  }

  constructor(private globals: Globals, private miservicio: TurismoService, private router: Router) { }

  ngOnInit() {
    this.place();
  }


  place(){
    this.miservicio.visita().subscribe(res=>{
      this.sitios = res['data'];
    },
    e=>{
      if(e.code === 0){
        this.texto = 'No hay conexion de con el servidor, HOST no disponible. Revisar conexion de red.'
      }
      if(e.code === 500){
        this.texto = 'Servidor no puede accesar a base de datos'
      }
      this.router.navigate(['menu/error', this.texto]);

    }

    )

  }

}
